import React from 'react';
import { Grid, Paper } from '@material-ui/core';

const WireFrames = ({wireframes, mobile}) => {
    return wireframes.map(wireframe => (
      <Grid item xs="12" sm="6" md={mobile ? "3" : "6"}>
        <Paper>
          <img src={wireframe} width={"100%"} alt={'wireframe'} />
        </Paper>
      </Grid>
    ));
}

export default WireFrames;