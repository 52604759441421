import React, { useCallback, useContext } from 'react';
import Box from "@material-ui/core/Box";
import ProjectCard from "../components/ProjectCard";
import ScreenBox from '../components/ScreenBox';
import { Grid } from '@material-ui/core';
import { WORK } from '../constants';
import { motion } from 'framer-motion';
import { MainContext } from '../App'
import Work from './Work';
import { DrivetimeShowcase } from './DrivetimeShowcase';

const Projects = () => {
    const {activeWork, setActiveWork} = useContext(MainContext);

    const renderWorkList = useCallback(() => {
        return (
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 30 }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 80
            }}
          >
            <Box textAlign={"center"} my={5}>
              <h3>Work</h3>
            </Box>
            <Grid container spacing={3}>
              <ProjectCard
                projectName={"Pit Boss"}
                image={"/work/pitboss/pitboss.png"}
                onClick={setActiveWork}
              />
              <ProjectCard
                projectName={"DriveTime"}
                image={"/work/drivetime/drivetime.png"}
                onClick={setActiveWork}
              />
            </Grid>
          </motion.div>
        );
    }, [])

    const renderWork = useCallback(() => {
      const work = WORK[activeWork];
      if (activeWork === 'DriveTime') {
        return <DrivetimeShowcase /> 
      };

      if (work) {
        return (
          <Work
            oldDesigns={work.oldDesigns}
            newDesigns={work.newDesigns}
            title={activeWork}
          />
        );
      }
    }, [activeWork]);

    return <ScreenBox>
        {!activeWork && renderWorkList()}
        {activeWork && renderWork()}
    </ScreenBox>
};

export default Projects