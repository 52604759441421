import React from "react";
import Box from "@material-ui/core/Box";
import { Grid, Button } from "@material-ui/core";

const ProjectCard = ({ projectName, image, onClick }) => {
  return (
    <Grid item xs={12} sm={4}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <a
          href={`#${projectName}`}
          onClick={() => onClick(projectName)}
          style={{ width: "100%" }}
        >
          <img src={image} width="100%" style={{borderRadius: 10}} alt={projectName} />
        </a>
        <Box component={"span"} my={2} textAlign="center">
          <Button
            href={`#${projectName}`}
            onClick={() => onClick(projectName)}
            color={"inherit"}
          >
            {projectName}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default ProjectCard;
