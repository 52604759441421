import React from 'react';
import Box from '@material-ui/core/Box';

const ScreenBox = ({ children }) => {
  return <Box
       display={"flex"}
       flexDirection={"column"}
       px={2}
       letterSpacing={2}
       flex={1}
       pb={8}
     >
       {children}
     </Box>;
}

export default ScreenBox;