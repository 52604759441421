import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import {COLORS} from "../theme";
import { motion } from "framer-motion";


require("./About.css");

export default function About() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <motion.div
      style={{flex: 1}}
      animate={{ opacity: 1, y: 0}}
      initial={{ opacity: 0, y: 30}}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 80
      }}
    >
      <Box
        height={'100%'}
        display={"flex"}
        flexDirection={"column"}
        p={matches ? 5 : 2}
        letterSpacing={2}
        justifyContent={"center"}
      >
        <h1>Hello, I'm Jenny.</h1>
        <Box fontSize={22}>UI/UX designer.</Box>
        <Box fontSize={22}>
          Enjoys creating{" "}
          <Box component={"span"} color={COLORS.PRIMARY}>
            beautiful and meaningful things.
          </Box>
        </Box>
        <Box fontSize={22}>Firm believer that pineapple goes on pizza.</Box>
      </Box>
    </motion.div>
  );
}
