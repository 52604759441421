import React from "react";
import { Grid, Paper } from "@material-ui/core";

const Lofi = ({ lofi, mobile }) => {
  return lofi.map(img => (
    <Grid item xs="12" sm="6" md={mobile ? "3" : "6"}>
      <Paper>
        <img src={img} width={"100%"} alt="lo-fi"/>
      </Paper>
    </Grid>
  ));
};

export default Lofi;
