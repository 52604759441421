import React from "react";
import { Grid, Paper } from "@material-ui/core";

const HiFis = ({ hifis, mobile }) => {
  return hifis.map(hifi => (
    <Grid item xs={mobile ? "12" : "6"}>
      {mobile ?  <img src={hifi} width={"100%"} /> : <Paper>
        <img src={hifi} width={"100%"} alt={'hifi'}/>
      </Paper>}
    </Grid>
  ));
};

export default HiFis;
