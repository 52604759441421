import React from 'react';
import {Box, Grid} from '@material-ui/core'

const CaseStudy = ({caseStudy}) => {
    return (
      <Box p={2} display={"flex"} flex={1} flexDirection={"column"}>
        {caseStudy.background && (
          <Box>
            <Box>
              <h4>Background</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.background }} />
          </Box>
        )}
        {caseStudy.problem && (
          <Box>
            <Box>
              <h4>Problem</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.problem }} />
            <Grid container spacing={2}>
              {caseStudy.images.problem &&
                caseStudy.images.problem.length > 0 &&
                caseStudy.images.problem.map(image => (
                  <Grid
                    item
                    xs={12}
                    sm={caseStudy.images.problem.length > 1 ? 6 : 12}
                  >
                    <Box py={2}>
                      <img src={image} width={"100%"} alt={'problem'} />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {caseStudy.audience && (
          <Box>
            <Box>
              <h4>Audience</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.audience }} />
            <Grid container spacing={2}>
              {caseStudy.images.users &&
                caseStudy.images.users.length > 0 &&
                caseStudy.images.users.map(image => (
                  <Grid
                    item
                    xs={12}
                    sm={caseStudy.images.users.length > 1 ? 6 : 12}
                  >
                    <Box py={2}>
                      <img src={image} width={"100%"} alt={'audience'}/>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {caseStudy.scope && (
          <Box>
            <Box>
              <h4>Scope / Contraints</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.scope }} />
            <Grid container spacing={2}>
              {caseStudy.images.scope &&
                caseStudy.images.scope.length > 0 &&
                caseStudy.images.scope.map(image => (
                  <Grid
                    item
                    xs={12}
                    sm={caseStudy.images.scope.length > 1 ? 6 : 12}
                  >
                    <Box py={2}>
                      <img src={image} width={"100%"} alt={'scope'}/>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {caseStudy.process && (
          <Box>
            <Box>
              <h4>Process</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.process }} />
          </Box>
        )}
        {caseStudy.outcome && (
          <Box>
            <Box>
              <h4>Outcome / Result</h4>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: caseStudy.outcome }} />
            {caseStudy.images.flow && (
              <Box
                py={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <img src={caseStudy.images.flow} width={"50%"} alt={'flow'} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
}

export default CaseStudy