import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import ButtonLink from "../ButtonLink";
import { Hidden, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

require("./Navigation.css");

export default function Navigation({ activeNav, onClick }) {
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl();

  const onAboutClick = () => {setAnchorEl(); onClick("about")}
  const onResumeClick = () => {setAnchorEl(); onClick("resume")}
  const onWorkClick = () => {setAnchorEl(); onClick("work")}
  const onProjectsClick = () => {setAnchorEl(); onClick("projects")}
  const onContactClick = () => {setAnchorEl(); onClick("contact")}

  return (
    <>
      <Hidden smUp>
        <Box
          className={"nav-bar"}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          p={3}
        >
          <MenuIcon fontSize={"large"} onClick={handleClick} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onAboutClick}>HOME</MenuItem>
            <MenuItem onClick={onResumeClick}>RESUME</MenuItem>
            <MenuItem onClick={onWorkClick}>WORK</MenuItem>
            <MenuItem onClick={onProjectsClick}>PROJECTS</MenuItem>
            <MenuItem onClick={onContactClick}>CONTACT</MenuItem>
          </Menu>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <Box
          className={"nav-bar"}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          p={3}
        >
          <ButtonLink
            active={activeNav === "about"}
            onClick={onAboutClick}
          >
            About me
          </ButtonLink>
          <ButtonLink
            active={activeNav === "resume"}
            onClick={onResumeClick}
          >
            Resume
          </ButtonLink>
          <ButtonLink
            active={activeNav === "work"}
            onClick={onWorkClick}
          >
            WORK
          </ButtonLink>
          <ButtonLink
            active={activeNav === "projects"}
            onClick={onProjectsClick}
          >
            Projects
          </ButtonLink>
          <ButtonLink
            active={activeNav === "contact"}
            onClick={onContactClick}
          >
            Contact
          </ButtonLink>
        </Box>
      </Hidden>
    </>
  );
}
