import { Container, Grid, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { COLORS } from "../theme";

const H1 = ({ children, ...props }) => {
  return (
    <h1 style={{ color: COLORS.SECONDARY }} {...props}>
      {children}
    </h1>
  );
};

const H4 = ({ children, ...props }) => {
  return (
    <h4 style={{ color: COLORS.SECONDARY_LIGHT }} {...props}>
      {children}
    </h4>
  );
};

const SectionDescription = ({ title, body }) => {
  return (
    <section>
      <H4>{title}</H4>
      <p style={{ whiteSpace: "pre-line" }}>{body}</p>
    </section>
  );
};

const SectionList = ({ title, listItems }) => {
  return (
    <section>
      <H4>{title}</H4>
      <ul>
        {listItems.map((item) => {
          if (Array.isArray(item)) {
            return (
              <ul>
                {item.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
            );
          }
          return <li key={item}>{item}</li>;
        })}
      </ul>
    </section>
  );
};

const ComparisonHeader = ({ children, ...props }) => (
  <H1
    style={{
      width: "100%",
      margin: 0,
      padding: 0,
      textAlign: "right",
      color: COLORS.ACCENT,
      borderBottom: `4px solid ${COLORS.ACCENT}`,
    }}
    {...props}
  >
    {children}
  </H1>
);

const ComparisonImage = (props) => (
  <img
    alt="comparison"
    width={"100%"}
    style={{ border: `4px solid ${COLORS.PURPLE}`, borderRadius: 5 }}
    {...props}
  />
);

const ProjectSection = ({ children }) => (
  <Paper style={{ padding: 32, borderRadius: 7, marginBottom: 66 }}>
    {children}
  </Paper>
);

const DrivetimeShowcase = () => {
  return (
    <Box>
      <ProjectSection>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <H1>VDP Browse</H1>
            <SectionDescription
              title={"What is VDP Browse?"}
              body={
                "VDP Browse experience gave our customers the ability to click to the next vehicle on VDP (Vehicle Details Page) without having to go back to the VSR (Vehicle Search Result) page."
              }
            />
            <SectionList
              title={"Thoughts & Process"}
              listItems={[
                "Not mutually exclusive from any other Transact experiments",
                "User variation for pre-approval, must persist for post-approval [Nice to have]",
                "Audience:",
                ["Transact customers only", "Mobile, Desktop, Tablet, and App"],
                "Exclude:",
                ["N/A"],
                "Split audience as follows:",
                ["Control: 34%", "Variation 1: 33%", "Variation 2: 33%"],
              ]}
            />
            <SectionDescription
              title={"Main KPI’s"}
              body={"Higher VDP views: Lead to sale"}
            />
            <SectionDescription
              title={"Results"}
              body={
                "We saw a 22% increase in our VDP views which led to a 2% increase in lead to app and a 3% increase in lead to sale. Due to this, we implemented VDP browse into our Prime experience and made the decision to bring it to HyperDrive."
              }
            />
          </Grid>
          {/* Comparison */}
          <Grid item xs={12} lg={8}>
            <Box
              display="flex"
              flexDirection={"row"}
              justifyContent="space-between"
            >
              <Box marginRight={'100px'} flex={1}>
                <Grid container>
                  <Grid item xs={3} lg={6}></Grid>
                  <Grid item xs={6}>
                    <Box marginBottom={4}>
                      <ComparisonHeader>Before</ComparisonHeader>
                    </Box>
                    <ComparisonImage
                      src="/work/drivetime/vdp/vdp-before.png"
                      alt="vdp-before"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box flex={1}>
                <Box marginBottom={4}>
                  <ComparisonHeader>After</ComparisonHeader>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/vdp/vdp-after-1.png"
                      alt="vdp-after-1"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/vdp/vdp-after-2.png"
                      alt="vdp-after-2"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ProjectSection>

      {/* Teased Terms */}
      <ProjectSection>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={4}>
            <H1>Teased Terms</H1>
            <SectionDescription
              title={"What is Teased Terms?"}
              body={
                "Today, our HyperDrive customers can see their terms on every car by clicking into a VDP (Vehicle Details Page). In this experiment, we will be showing HyperDrive customers their terms for each vehicle on our VSR page. This will allow the customer to shop cars on VSR without having to click into each VDP to view what their terms would be. \n\nIn this experiment, we will be showing 2 variations of “locked” terms on our VSR (Vehicle Search Results) page for our HyperDrive customers only. This gives them a preview of what they could see for each vehicle on VSR and encourage them to “unlock their terms” by filling out our Get Approved form online. "
              }
            />
            <SectionList
              title={"Thoughts & Process"}
              listItems={[
                "Create 2 variations of the vehicle cards on post approval VSR:",
                "Include terms on cards",
                [
                  "These terms will be dynamic to the customer",
                  "Terms displayed must be lowest terms available for these cards",
                  "Terms will be based off the most recently updated income",
                ],
                "Update CTA",
                [
                  "Remove button",
                  'Add text link that reads "Adjust Your Terms"',
                  "This will continue to route to the VDP",
                ],
                "Error Scenarios:",
                [
                  "If customer does not have terms available due to the not enough financed error:",
                  'Replace terms with "Call us for terms"',
                ],
                'CTA will read "Call Us" with dealership number and route to the dealership',
                [
                  "If terms are not available due to a financing call error:",
                  "Center price",
                  'CTA will read "See your terms" and will route to VDP',
                ],
              ]}
            />
            <SectionDescription
              title={"Main KPI’s"}
              body={
                "Pre-approval: Session to lead / Post-approval: Lead to sale"
              }
            />
            <SectionDescription title={"Results"} body={"Pending Results"} />
          </Grid>
          <Grid item xs={12} lg={8}>
            {/* ROW 1 */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box marginBottom={4}>
                  <ComparisonHeader>Before</ComparisonHeader>
                </Box>
                <ComparisonImage
                  src="/work/drivetime/teased-terms/teased-terms-before.png"
                  alt="teased-terms-before"
                />
              </Grid>
              <Grid item xs={8}>
                <Box position="relative" marginBottom={4}>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 12,
                      color: COLORS.ACCENT,
                      weight: 500,
                    }}
                  >
                    Variation 1
                  </span>
                  <ComparisonHeader>After</ComparisonHeader>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/teased-terms/var-1-unlock.png"
                      alt="var-1-unlock"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/teased-terms/var-1-adjust.png"
                      alt="var-1-adjust"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ROW 2 */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box marginBottom={4}>
                  <ComparisonHeader>Before</ComparisonHeader>
                </Box>
                <ComparisonImage
                  src="/work/drivetime/teased-terms/teased-terms-before.png"
                  alt="teased-terms-before"
                />
              </Grid>
              <Grid item xs={8}>
                <Box position="relative" marginBottom={4}>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 12,
                      color: COLORS.ACCENT,
                      weight: 500,
                    }}
                  >
                    Variation 2
                  </span>
                  <ComparisonHeader>After</ComparisonHeader>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/teased-terms/var-2-unlock.png"
                      alt="var-2-unlock"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/teased-terms/var-2-adjust.png"
                      alt="var-2-adjust"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ProjectSection>

      {/* HYPERDRIVE */}
      <ProjectSection>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <H1>HyperDrive</H1>
            <SectionDescription
              title={"What is HyperDrive?"}
              body={
                "Today, our customers do not have the ability to customize their terms or send any information to the dealership or add on any products.. HyperDrive allows users to save hours at the dealership since all of their information will be ready upon arrival."
              }
            />
            <SectionList
              title={"Thoughts & Process"}
              listItems={[
                'Show two down payments:',
                ['"Just the car"', 'Shows monthly payment without ancillary products', '"With Coverage" - language still pending legal approval', 'Show monthly payment with all optional products'],
                'If customer is not eligible for certain products, show an "X "next to the product and grey out. Calculate monthly payment with all products customer is eligible for',
                'Follow the hierarchy of products to determine which ones to show in the with coverage payment box',
                'Tool tip next to with coverage:',
                ['Take them too the APR and Financing Details Modal', 'As customer adjusts their down payment, both monthly payments will update',],
                'Update APR and Financing Details Modal:',
                ['Styling changes and verbiage changes'],
                'Show terms for both monthly payments',
                'Match eligibility logic that goes into calcing the monthly payment with optional products',
                'If a customer is not eligible for a product listed, put an X in that box'
              ]}
            />
            <SectionDescription
              title={"Main KPI’s"}
              body={"Anicillary Purchase / Lead to sale / In Dealership Purchase"}
            />
            <SectionDescription
              title={"Results"}
              body={
                "Pending Development"
              }
            />
          </Grid>
          {/* Comparison */}
          <Grid item xs={12} lg={8}>
            <Box
              display="flex"
              flexDirection={"row"}
              justifyContent="space-between"
            >
              <Box marginRight={'100px'} flex={1}>
                <Grid container>
                  <Grid item xs={3} lg={6}></Grid>
                  <Grid item xs={6}>
                    <Box marginBottom={4}>
                      <ComparisonHeader>Before</ComparisonHeader>
                    </Box>
                    <ComparisonImage
                      src="/work/drivetime/hyperdrive/hyperdrive-before.png"
                      alt="hyperdrive-before"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box flex={1}>
                <Box marginBottom={4}>
                  <ComparisonHeader>After</ComparisonHeader>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/hyperdrive/hyperdrive-after-1.png"
                      alt="hyperdrive-after-1"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ComparisonImage
                      src="/work/drivetime/hyperdrive/hyperdrive-after-2.png"
                      alt="hyperdrive-after-2"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ProjectSection>
    </Box>
  );
};

export { DrivetimeShowcase };
