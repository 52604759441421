import React, { useCallback, useContext } from 'react';
import Box from "@material-ui/core/Box";
import ProjectCard from "../components/ProjectCard";
import ScreenBox from '../components/ScreenBox';
import { Grid } from '@material-ui/core';
import Project from './Project';
import { PROJECTS } from '../constants';
import { motion } from 'framer-motion';
import { MainContext } from '../App'
import Concepts from '../components/Concepts';

const Projects = () => {
    const {activeProject, setActiveProject} = useContext(MainContext);

    const renderProjectList = useCallback(() => {
        return (
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 30 }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 80
            }}
          >
            <Box textAlign={"center"} my={5}>
              <h3>User Interface Projects</h3>
            </Box>
            <Grid container spacing={3}>
              <ProjectCard
                projectName={"Festiplex"}
                image={"/mockups/festiplex.png"}
                onClick={setActiveProject}
              />
              <ProjectCard
                projectName={"Med Planner"}
                image={"/mockups/medplanner.png"}
                onClick={setActiveProject}
              />
              <ProjectCard
                projectName={"Pet Adoption"}
                image={"/mockups/petadoption.png"}
                onClick={setActiveProject}
              />
              <ProjectCard
                projectName={"Flash Seats Redesign"}
                image={"/mockups/flashseats.png"}
                onClick={setActiveProject}
              />
              <ProjectCard
                projectName={"Concepts"}
                image={"/mockups/concept.png"}
                onClick={setActiveProject}
              />
            </Grid>
          </motion.div>
        );
    }, [])

    const renderProject = useCallback(() => {
      if(activeProject === 'Concepts') {
        return <Concepts />
      }
      const project = PROJECTS[activeProject];
      if (project) {
        return (
          <Project
            projectHeader={project.projectHeader}
            projectSubtitle={project.projectSubtitle}
            wireframes={project.wireframes}
            hifi={project.hifis}
            designSystem={project.designSystem}
            lofi={project.lofis}
            caseStudy={project.caseStudy}
            mobile={project.mobile}
            handleBack={setActiveProject}
            prototype={project.prototype}
          />
        );
      }
    }, [activeProject]);

    return <ScreenBox>
        {!activeProject && renderProjectList()}
        {activeProject && renderProject()}
    </ScreenBox>
};

export default Projects