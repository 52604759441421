import React from 'react';
import Box from '@material-ui/core/Box';
import ScreenBox from '../components/ScreenBox';
import { Paper, Button } from '@material-ui/core';
import { COLORS } from '../theme';

const Resume = () => {
  return (
    <ScreenBox>
      <Box display={"flex"} flexDirection="column" alignItems="center" my={2}>
        <Box alignSelf="flex-end">
          <a href="resume.docx" download>
            <Button
              variant={"contained"}
              size={"small"}
              style={{
                borderColor: COLORS.SECONDARY,
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: COLORS.SECONDARY,
                marginRight: '12px'
              }}
            >
              Word Doc 
            </Button>
          </a>
          <a href="resume.pdf" download>
            <Button
              variant={"contained"}
              size={"small"}
              style={{
                borderColor: COLORS.PRIMARY,
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: COLORS.PRIMARY
              }}
            >
              Download
            </Button>
          </a>
        </Box>
      </Box>
      <Box mb={2} height="100vh">
          <iframe src="resume.pdf" width={"100%"} height="100%" alt="resume" title="Jenny Le resume" />
      </Box>
    </ScreenBox>
  );
}

export default Resume;