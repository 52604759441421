import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';
import { CONCEPTS } from "../constants";

console.warn('concepts', CONCEPTS)

const Concepts = () => {
    return (
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 30 }}
        transition={{
          type: "spring",
          damping: 10,
          stiffness: 80
        }}
      >
        <Box textAlign={"center"} my={5}>
          <h3>Concepts</h3>
        </Box>
        <Grid container spacing={2}>
          {CONCEPTS.map((concept, i) => (
            <Grid item xs={12} sm={6}>
              <img
                src={concept}
                key={i}
                alt={"concept-art"}
                width={"100%"}
                style={{ borderRadius: 10 }}
              />
            </Grid>
          ))}
        </Grid>
      </motion.div>
    );
}

export default Concepts