import React from "react";
import { Box, useMediaQuery, Link, Fab } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import {COLORS} from "../theme";
import { motion } from "framer-motion";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import { pink, lightBlue, deepOrange } from "@material-ui/core/colors";


const LinkedIn = withStyles(theme => ({
  root: {
    color: 'white',
    margin: 8,
    backgroundColor: lightBlue[700],
    "&:hover": {
      backgroundColor: lightBlue[700]
    }
  }
}))(Fab);

const Instagram = withStyles(theme => ({
  root: {
    color: "white",
    margin: 8,
    backgroundColor: pink[700],
    "&:hover": {
      backgroundColor: pink[700]
    }
  }
}))(Fab);

const Email = withStyles(theme => ({
  root: {
    color: "white",
    margin: 8,
    backgroundColor: deepOrange[700],
    "&:hover": {
      backgroundColor: deepOrange[700]
    }
  }
}))(Fab);

const variants = {
  visible: i => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.3
    }
  }),
};

export default function Contact() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <motion.div
      style={{ flex: 1 }}
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 30 }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 80
      }}
    >
      <Box
        height={'100%'}
        display={"flex"}
        flexDirection={"column"}
        p={matches ? 5 : 2}
        letterSpacing={2}
        justifyContent={"center"}
        flex={1}
      >
        <h1>Does pineapple go on pizza? Let’s talk about it.</h1>

        <Box component={"span"} color={COLORS.PRIMARY} fontSize={22}>
          <Link
            href="mailto:lejenny75@gmail.com"
            color="inherit"
            target="_blank"
          >
            lejenny75@gmail.com
          </Link>
        </Box>
        <Box
          p={2}
          m={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <motion.span
            custom={0}
            animate="visible"
            initial={{ opacity: 0, x: 10 }}
            variants={variants}
          >
            <LinkedIn
              href={"https://www.linkedin.com/in/jenny-le-5a5172154/"}
              target="_blank"
            >
              <LinkedInIcon />
            </LinkedIn>
          </motion.span>
          <motion.span
            custom={2}
            animate="visible"
            initial={{ opacity: 0, x: 10 }}
            variants={variants}
          >
            <Email href="mailto:lejenny@gmail.com" target="_blank">
              <EmailIcon />
            </Email>
          </motion.span>
        </Box>
      </Box>
    </motion.div>
  );
}
