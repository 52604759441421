import React, { useState, useCallback, useMemo } from 'react';
import { Grid, Box, Divider, Hidden, Menu, Button, MenuItem } from '@material-ui/core';
import ButtonLink from '../components/ButtonLink';
import WireFrames from '../components/WireFrames';
import HiFis from '../components/HiFis';
import DesignSystems from '../components/DesignSystems';
import Lofi from '../components/Lofi';
import CaseStudy from '../components/CaseStudy';
import { motion } from "framer-motion";
import { COLORS } from '../theme';

const Project = ({
  projectHeader,
  projectSubtitle,
  wireframes,
  hifi,
  designSystem,
  lofi,
  mobile,
  caseStudy,
  handleBack,
  prototype
}) => {
  const [activeTab, setActiveTab] = useState("wireframe");
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (tab) => {
    if(!tab) {
      handleBack()
    }
    if(tab === 'prototype') {
      window.open(prototype)
    } else {
      setActiveTab(tab)
    };
    setAnchorEl(null);
  };

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case "wireframe":
        return <WireFrames wireframes={wireframes} mobile={mobile} />;
      case "hifi":
        return <HiFis hifis={hifi} mobile={mobile} />;
      case "lofi":
        return <Lofi lofi={lofi} mobile={mobile} />;
      case "designSystem":
        return <DesignSystems designs={designSystem} />;
      case "caseStudy":
        return <CaseStudy caseStudy={caseStudy} />;
      default:
        return null;
    }
  }, [activeTab]);

  const tabLinks = useMemo(() => {
    return (
      <Hidden xsDown>
        <Box display={"flex"} justifyContent="center" alignItems="center">
          <ButtonLink alignSelf={"flexStart"} onClick={() => handleBack()}>
            Back
          </ButtonLink>
          <ButtonLink
            active={activeTab === "wireframe"}
            onClick={() => setActiveTab("wireframe")}
          >
            Wireframe
          </ButtonLink>
          <ButtonLink
            active={activeTab === "lofi"}
            onClick={() => setActiveTab("lofi")}
          >
            Lo-Fi
          </ButtonLink>
          <ButtonLink
            active={activeTab === "hifi"}
            onClick={() => setActiveTab("hifi")}
          >
            Final Design
          </ButtonLink>
          <ButtonLink
            active={activeTab === "caseStudy"}
            onClick={() => setActiveTab("caseStudy")}
          >
            Case Study
          </ButtonLink>
          <ButtonLink
            active={activeTab === "designSystem"}
            onClick={() => setActiveTab("designSystem")}
          >
            Design System
          </ButtonLink>
          <ButtonLink href={prototype} target={"_blank"}>
            Prototype
          </ButtonLink>
        </Box>
      </Hidden>
    );
  }, [activeTab])

  const menuItem = useMemo(() => {
    return (
      <Hidden smUp>
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems="center"
          my={2}
        >
          <Button
            variant={"contained"}
            size={"small"}
            style={{
              borderColor: COLORS.PRIMARY,
              color: "white",
              fontWeight: "bold",
              backgroundColor: COLORS.PRIMARY
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleClose()}>Back</MenuItem>
            <MenuItem onClick={() => handleClose("wireframe")}>
              Wireframe
            </MenuItem>
            <MenuItem onClick={() => handleClose("lofi")}>Lo-Fi</MenuItem>
            <MenuItem onClick={() => handleClose("hifi")}>
              Final Design
            </MenuItem>
            <MenuItem onClick={() => handleClose("caseStudy")}>
              Case Study
            </MenuItem>
            <MenuItem onClick={() => handleClose("designSystem")}>
              Design System
            </MenuItem>
            <MenuItem href="google.com" onClick={() => handleClose("prototype")}>
              Prototype
            </MenuItem>
          </Menu>
        </Box>
      </Hidden>
    );
  }, [anchorEl])

  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 30 }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 80
      }}
    >
      <Grid container spacing="5">
        <Grid item xs={12}>
          <Box px={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              textAlign="center"
            >
              <h3>{projectHeader}</h3>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              textAlign="center"
            >
              {projectSubtitle}
            </Box>
          </Box>
          {tabLinks}
          {menuItem}
          <Box my={2} px={5}>
            <Divider />
          </Box>
        </Grid>
        {renderTab()}
      </Grid>
    </motion.div>
  );
};

export default Project;