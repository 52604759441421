import React from 'react';
import {Box, Button} from '@material-ui/core';
import { COLORS } from '../theme';

const ButtonLink = ({active, onClick, children, justifySelf, alignSelf, href, target}) => {
    return (
      <Box
        justifySelf={justifySelf}
        alignSelf={alignSelf}
        component={"span"}
        color={active ? COLORS.PRIMARY : "black"}
        m={2}
      >
        <Button href={href} target={target} color="inherit" onClick={onClick}>
          {children}
        </Button>
      </Box>
    );
}

export default ButtonLink;