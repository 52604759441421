export const PROJECTS = {
  Festiplex: {
    mobile: true,
    projectHeader: "Festiplex - UI/UX Project",
    projectSubtitle:
      "This was an UI/UX project for festival go-ers to have all of their events in one app",
    wireframes: [
      "/mockups/Festiplex/wireframes/screen1.png",
      "/mockups/Festiplex/wireframes/screen2.png",
      "/mockups/Festiplex/wireframes/screen3.png",
      "/mockups/Festiplex/wireframes/screen4.png",
      "/mockups/Festiplex/wireframes/screen5.jpg",
      "/mockups/Festiplex/wireframes/screen6.png",
      "/mockups/Festiplex/wireframes/screen7.png",
    ],
    lofis: [
      "/mockups/Festiplex/lofi/screen1.jpg",
      "/mockups/Festiplex/lofi/screen2.jpg",
      "/mockups/Festiplex/lofi/screen3.jpg",
      "/mockups/Festiplex/lofi/screen4.jpg",
      "/mockups/Festiplex/lofi/screen5.jpg",
      "/mockups/Festiplex/lofi/screen6.jpg",
      "/mockups/Festiplex/lofi/screen7.jpg",
    ],
    hifis: ["/mockups/Festiplex/screen1.png", "/mockups/Festiplex/screen2.png"],
    caseStudy: {
      background:
        "Festiplex - An app for music festival and events around the world",
      problem:
        "Tiana lives to go to music festivals. I noticed she had over 15 apps in her phone for festivals and I watched her switch back in forth to look for artists, set times, maps, ticket prices - etc etc.",
      audience:
        "Festiplex is designed for music/festival lovers that want the convince of being able to have all of their information in one app. The audience is usually anyone between 18-30 who love to explore and travel for events.",
      scope:
        "One of my biggest frustrations with the apps was signing up to use them. A lot of them only gave a facebook login option. Once I created my facebook, I had issues navigating through a few of the apps being that they are only active when the event is current. As soon as I found events that were active, I sat down with my user and had her test the usability and interviewed her. I used the post-it method for the first time, which helped me give a better insight of how I would like the user to navigate through my project.",
      process:
        "As this project remain a personal project and my first user experience design project I thought important to create a good UX strategy: <br /><br />Empathize - User interviews, User Journey and User personas<br /><br />Define - Frustrations, Solutions & User Flow<br /><br />Ideate - Wireframes<br /><br />Prototype - Hi-Fi<br /><br />Validate - User testing & User interviews",
      outcome:
        "I had Tiana go back to my design and use it through prototype. I saw where he struggled and asked her what I could have done to make it more efficient for him. With his feed back, I made changes to the users needs. <br /><br />A huge take away from this project, being my first design. Is to spend more time on the wireframe. I didn’t realize the importance of it until I got to my final design, sat there and struggled.",
      images: {
        scope: ["/mockups/Festiplex/case_study/scope.png"],
        users: [
          "/mockups/Festiplex/case_study/user1.png",
          "/mockups/Festiplex/case_study/user2.png",
        ],
        flow: "/mockups/Festiplex/case_study/flow.png",
      },
    },
    designSystem: [
      "/mockups/Festiplex/design_systems/screen1.png",
      "/mockups/Festiplex/design_systems/screen2.png",
      "/mockups/Festiplex/design_systems/screen3.png",
      "/mockups/Festiplex/design_systems/screen4.png",
    ],
    prototype:
      "https://www.figma.com/proto/LpkfC7WghNSIr7aFhZYyQn/Festiplex-Final-Design?node-id=1%3A2&scaling=scale-down",
  },
  "Med Planner": {
    mobile: true,
    projectHeader: "Med Planner - UI/UX Project",
    projectSubtitle:
      "This was an UI/UX project for users to track their medicine and associated schedule / dosage.",
    wireframes: [
      "/mockups/MedPlanner/wireframes/screen1.png",
      "/mockups/MedPlanner/wireframes/screen2.png",
      "/mockups/MedPlanner/wireframes/screen3.png",
      "/mockups/MedPlanner/wireframes/screen4.png",
      "/mockups/MedPlanner/wireframes/screen5.png",
      "/mockups/MedPlanner/wireframes/screen6.png",
      "/mockups/MedPlanner/wireframes/screen7.png",
    ],
    lofis: [
      "/mockups/MedPlanner/lofis/screen1.png",
      "/mockups/MedPlanner/lofis/screen2.png",
      "/mockups/MedPlanner/lofis/screen3.png",
      "/mockups/MedPlanner/lofis/screen4.png",
      "/mockups/MedPlanner/lofis/screen5.png",
      "/mockups/MedPlanner/lofis/screen6.png",
      "/mockups/MedPlanner/lofis/screen7.png",
      "/mockups/MedPlanner/lofis/screen8.png",
      "/mockups/MedPlanner/lofis/screen9.png",
      "/mockups/MedPlanner/lofis/screen10.png",
      "/mockups/MedPlanner/lofis/screen11.png",
    ],
    hifis: [
      "/mockups/MedPlanner/screen1.png",
      "/mockups/MedPlanner/screen2.png",
      "/mockups/MedPlanner/screen3.png",
    ],
    caseStudy: {
      background:
        "As someone who worked in the medical/pharmacy field, I know first hand that balancing medication is one of the more stressful thing for pharmacist/patient relationships.<br /><br />A study published by Mayo Clinic a few years back reveals that 70% of Americans take at least one prescription medication. The same study shows that 20% of Americans are on five or more medications. It was also revealed that prescription drug use among Americans has steadily increased over the past 10 years.Festiplex - An app for music festival and events around the world",
      problem:
        "When you start taking 5 or more prescription drugs, things start getting a little bit more difficult to maintain. The look of your prescription medicine can change every time you pick up your medicine, due to different manufactures/makers, which leads to a ton of confusion for the patient.<br /><br />Patients who travel and find a pharmacy because they forgot one of their medicine back home. I heard a lot of “I forgot my little pink pill at home” or “I’m not sure what I forgot but just transfer them all over here so I can get them filled and picked up”. Of course they are frustrated, They are on vacation and this should be the last of their worries.<br /><br />I asked myself, Wouldn’t it be so much easier if there was a way you can show your local pharmacy what ACTIVE medications you’re taking? Instead of carrying around all of your empty bottles or a huge list, it should be in the convince of your phone.",
      audience:
        "I was able to get in touch with my patients at the pharmacy I previously worked at. With their approval, I was able to interview 5 of them.<br /><br />Who are they? They are from the age of 25-60. Their health has gone a bit down naturally from aging or genetics. They aren’t too tech savvy but know enough to have smart phones and able to learn how to use new apps.",
      scope: "",
      process:
        "My first step was to really get to know the patients/users. I was allowed to follow a few of my former patients through their pharmacy visit process. It gave me a better insight (since I am not behind the counter anymore.) I asked them to download a few other med planning apps and to have them use it for a few weeks. They gave me a list of issues and frustrations they ran in with the apps & what they would change.<br /><br />Since I was unable to bring my design to life in code, I had them go through the live prototype. I was able to see where they would click, what interactions they would have and was able to correct my designs to the users needs.",
      outcome:
        "This being my first UX study with non friend/family relationships. I’ve learned a lot of things through the process and how important user feedback and tests are. Most important I learned how crucial interactions are to reach the best possible product.",
      images: {
        scope: ["/mockups/MedPlanner/case_study/scope.png"],
        problem: [
          "/mockups/MedPlanner/case_study/problem1.png",
          "/mockups/MedPlanner/case_study/problem2.png",
        ],
        users: [
          "/mockups/MedPlanner/case_study/user1.png",
          "/mockups/MedPlanner/case_study/user2.png",
        ],
        flow: "/mockups/MedPlanner/case_study/flow.png",
      },
    },
    designSystem: [
      "/mockups/MedPlanner/design_systems/screen1.png",
      "/mockups/MedPlanner/design_systems/screen2.png",
      "/mockups/MedPlanner/design_systems/screen3.png",
      "/mockups/MedPlanner/design_systems/screen4.png",
      "/mockups/MedPlanner/design_systems/screen5.png",
    ],
    prototype:
      "https://www.figma.com/proto/vCs1HfEjyJTMb4a5uADiCR/Med-Planner-Final-Design?scaling=scale-down&node-id=1%3A3",
  },
  "Flash Seats Redesign": {
    mobile: false,
    projectHeader: "Flash Seats - UI/UX Project",
    projectSubtitle: "",
    wireframes: [
      "/mockups/FlashSeats/wireframes/screen1.png",
      "/mockups/FlashSeats/wireframes/screen2.png",
      "/mockups/FlashSeats/wireframes/screen3.png",
      "/mockups/FlashSeats/wireframes/screen4.png",
      "/mockups/FlashSeats/wireframes/screen5.png",
    ],
    lofis: [
      "/mockups/FlashSeats/lofis/screen1.png",
      "/mockups/FlashSeats/lofis/screen2.png",
      "/mockups/FlashSeats/lofis/screen3.png",
      "/mockups/FlashSeats/lofis/screen4.png",
      "/mockups/FlashSeats/lofis/screen5.png",
      "/mockups/FlashSeats/lofis/screen6.png",
    ],
    hifis: [
      "/mockups/FlashSeats/screen1.png",
      "/mockups/FlashSeats/screen2.png",
      "/mockups/FlashSeats/screen3.png",
      "/mockups/FlashSeats/screen4.png",
      "/mockups/FlashSeats/screen5.png",
      "/mockups/FlashSeats/screen6.png",
    ],
    caseStudy: {
      background:
        "Flash Seats Tickets - Buy, sell or transfer tickets through their website or app.",
      problem:
        "My friend Gavin purchased tickets to a Madeon concert. He was unable to attend them and asked me if I’d be willing to take the tickets off his hands. Once we figured out that we had to use flash seats, We were immediately concerned about using their website/app because it was so dated.<br /><br />After a little digging around to make sure the website was safe, we spent more than 30 minutes trying to figure out how to transfer tickets to my name. Gavin was so frustrated he didn’t want to move forward at one point. My goal was to redesign Flash Seats for a better user experience.",
      audience:
        "Flash Seats was designed for people who want to buy, sell or transfer their tickets. It was meant to be quick and easy. There needs to be a sense of security when buying/transferring tickets.",
      scope:
        "My biggest issue was having someone to test out the website. Since the website is so dated, everyone I asked felt very skeptical using it.",
      process:
        "I spent a few weeks researching the website. I signed up, explored the website from front to back. I even went as far as purchasing tickets through them. I also downloaded the app hoping that it would be more user friendly (it wasn’t). I ended up transferring my tickets to someone but it was not an easy process.<br /><br />My steps were Background Research, Usability testing, and Research to understand the effectiveness of what flash seats used compared to others.<br /><br />Before I started my design, I researched into many other buy/sell/transfer websites. I had to compare what made them different, what made them more appealing and why I would choose one over the other.",
      outcome:
        "I had Gavin go back to my design and use it through prototype. I saw where he struggled and asked him what I could have done to make it more efficient for him. With his feed back, I made changes to the users needs.<br /><br />If I could go back and do the re-design again. I would try not to compare it to so many other websites and be so hard on my own. I did a lot of second guessing and lost focus that it was what the audience needs and not my own. So I redirected myself and got back on track for the user experience.",
      images: {
        scope: [
          "/mockups/FlashSeats/case_study/scope1.png",
          "/mockups/FlashSeats/case_study/scope2.png",
        ],
        users: [
          "/mockups/FlashSeats/case_study/user1.png",
          "/mockups/FlashSeats/case_study/user2.png",
        ],
      },
    },
    designSystem: [
      "/mockups/FlashSeats/design_systems/screen1.png",
      "/mockups/FlashSeats/design_systems/screen2.png",
      "/mockups/FlashSeats/design_systems/screen3.png",
      "/mockups/FlashSeats/design_systems/screen4.png",
    ],
    prototype:
      "https://www.figma.com/proto/ilCxPnxpCxeCfbzcsrlaAq/Flash-Seats-Redesign-Final?scaling=min-zoom&node-id=5%3A2",
  },
  "Pet Adoption": {
    mobile: true,
    projectHeader: "Pet Adoption - UI/UX Project",
    projectSubtitle: "",
    wireframes: [
      "/mockups/PetAdoption/wireframes/login.png",
      "/mockups/PetAdoption/wireframes/profile.png",
      "/mockups/PetAdoption/wireframes/message.png",
      "/mockups/PetAdoption/wireframes/message2.png",
      "/mockups/PetAdoption/wireframes/adoption.png",
      "/mockups/PetAdoption/wireframes/favs.png",
    ],
    lofis: [
      "/mockups/PetAdoption/lofis/login.png",
      "/mockups/PetAdoption/lofis/profile.png",
      "/mockups/PetAdoption/lofis/message.png",
      "/mockups/PetAdoption/lofis/message2.png",
      "/mockups/PetAdoption/lofis/adoption.png",
      "/mockups/PetAdoption/lofis/favs.png",
    ],
    hifis: [
      "/mockups/PetAdoption/screen1.png",
      "/mockups/PetAdoption/screen2.png",
    ],
    caseStudy: {
      audience: " ",
      scope: " ",
      outcome: " ",
      images: {
        scope: [
          "/mockups/PetAdoption/case_study/questions1.png",
          "/mockups/PetAdoption/case_study/questions2.png",
        ],
        users: ["/mockups/PetAdoption/case_study/user1.png"],
        flow: "/mockups/PetAdoption/case_study/flow.png",
      },
    },
    designSystem: [
      "/mockups/PetAdoption/design_systems/color.png",
      "/mockups/PetAdoption/design_systems/icon.png",
      "/mockups/PetAdoption/design_systems/typography.png",
      "/mockups/PetAdoption/design_systems/button.png",
      "/mockups/PetAdoption/design_systems/input.png",
    ],
    prototype:
      "https://www.figma.com/proto/vym1pY81TEdH0tVhWPwy5y/Pet-Adoption-Final-Design?node-id=1%3A4&scaling=scale-down",
  },
};

export const CONCEPTS = [
  "/mockups/concepts/concept1.png",
  "/mockups/concepts/concept2.png",
  "/mockups/concepts/concept3.png",
];

export const WORK = {
  "Pit Boss": {
    oldDesigns: ['/work/pitboss/old_product.png', '/work/pitboss/old_recipe.png',  '/work/pitboss/old_category.png', '/work/pitboss/old_footer.png',],
    newDesigns: ['/work/pitboss/new_product.png', '/work/pitboss/new_recipe.png',  '/work/pitboss/new_category.png', '/work/pitboss/new_footer.png',]
  }
}
  
