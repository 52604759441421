import React from "react";
import { Grid, Paper } from "@material-ui/core";

const DesignSystems = ({ designs }) => {
  return designs.map(design => (
    <Grid item xs={12} sm={6} md={3}>
      <Paper>
        <img src={design} width={"100%"} />
      </Paper>
    </Grid>
  ));
};

export default DesignSystems;
