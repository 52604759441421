import "./App.css";

import { Container, BottomNavigation } from "@material-ui/core";
import React, { useState } from "react";

import Navigation from "./components/Navigation/Navigation";
import About from "./screens/About";
import Projects from "./screens/Projects";
import Resume from "./screens/Resume";
import Works from "./screens/Works";
// import MyBottomNavigation from "./components/Navigation/BottomNavigation";
import Contact from "./screens/Contact";

const MainState = {
  currentPage: 'about',
  activeProject: undefined
}

export const MainContext = React.createContext(MainState);

function App() {
  const [currentPage, setCurrentPage] = useState("about");
  const [activeProject, setActiveProject] = useState();
  const [activeWork, setActiveWork] = useState();

  const handleNavClick = page => {
    if(page === 'projects') {
      setActiveProject(undefined);
    }
    if(page === 'work') {
      setActiveWork(undefined);
    }
    setCurrentPage(page);
  }

  const renderCurrentPage = () => {
    switch (currentPage) {
      case "about":
        return <About />;
      case "projects":
        return <Projects />;
      case "work":
        return <Works />;
      case "resume":
        return <Resume />;
      default:
        return <Contact />;
    }
  };

  return (
    <MainContext.Provider
      value={{ currentPage, setCurrentPage, activeProject, setActiveProject, activeWork, setActiveWork }}
    >
      <Container className="app-container" maxWidth={'xl'}>
        <Navigation
          onClick={handleNavClick}
          activeNav={currentPage}
          exitBeforeEnter={true}
        />
        {renderCurrentPage()}
      </Container>
    </MainContext.Provider>
  );
}

export default App;
