import React, { useState, useCallback, useMemo } from "react";
import {
  Grid,
  Box,
  Divider,
} from "@material-ui/core";
import ButtonLink from "../components/ButtonLink";
import { motion } from "framer-motion";

const Work = ({oldDesigns, newDesigns, title}) => {
  const [activeTab, setActiveTab] = useState("new");

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case "old":
        return oldDesigns.map(design => (
            <Grid item xs={12}>
                <img src={design} width={"100%"} />
            </Grid>
          ));
      case "new":
        return newDesigns.map(design => (
            <Grid item xs={12}>
                <img src={design} width={"100%"} />
            </Grid>
          ));
      default:
        return null;
    }
  }, [activeTab]);

  const tabLinks = useMemo(() => {
    return (
      <Box display={"flex"} justifyContent="center" alignItems="center">
        <ButtonLink
          active={activeTab === "new"}
          onClick={() => setActiveTab("new")}
        >
          New Designs
        </ButtonLink>
        <ButtonLink
          active={activeTab === "old"}
          onClick={() => setActiveTab("old")}
        >
          Old Designs
        </ButtonLink>
      </Box>
    );
  }, [activeTab]);

  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 30 }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 80,
      }}
    >
      <Grid container spacing="5">
        <Grid item xs={12}>
          <Box px={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              textAlign="center"
            >
              <h3>{title}</h3>
            </Box>
          </Box>
          {tabLinks}
          <Box my={2} px={5}>
            <Divider />
          </Box>
        </Grid>
        {renderTab()}
      </Grid>
    </motion.div>
  );
};

export default Work;
